
<template>
    <div class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-4 w-full md:w-2/3 lg:w-1/2 2xl:w-1/2 max-h-[38rem] overflow-y-auto">
                
                    <div class="flex items-start justify-between">
                        <button 
                            title="Chiudi"
                            @click.prevent="$emit('closeModal')"
                            class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                    <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                        c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                        c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                                </svg>
                        </button>
                    </div>
                <template v-if="!maintainance">
                    <h1 class="text-xl flex items-center space-x-1 dark:text-white">
                        <span class="">{{ $t('newInventoryModal.title', $store.state.locale) }}</span>
                    </h1>
                    <p>
                        {{ $t('newInventoryModal.msg', $store.state.locale) }}
                    </p>
                    <!-- Date Selection -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                        <!-- Month -->
                        <div class="mb-1 sm:mb-2">
                            <label for="from" class="inline-block mb-1 uppercase">{{ $t('selectDateModal.month', $store.state.locale) }}</label>
                            <div class="w-full relative">
                                <select v-model="selected_month" @change.prevent="setSelectedDate" class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400">
                                    <option value="0" selected disabled>{{ $t('selectDateModal.month_select', $store.state.locale) }}</option>
                                    <option
                                        v-for="(m, i) in months"  :key="i"
                                        :value="i+1"  
                                    >
                                        {{ $t(`months.${m}`, $store.state.locale) }}
                                    </option>
                                </select>
                                <span class="absolute right-4 top-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                                </span>
                            </div>
                            <span
                                v-if="$store.state.errors.errors.from" 
                                class="font-bold text-sm text-red-500" >
                                {{ $store.state.errors.errors.from[0] }}
                            </span>
                        </div>
                        <!-- Year -->
                        <div class="mb-1 sm:mb-2">
                            <label for="from" class="inline-block mb-1 uppercase">{{ $t('selectDateModal.year', $store.state.locale) }}</label>
                            <div class="w-full relative">
                                <select v-model="selected_year"  @change.prevent="setSelectedDate" class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400">
                                    <option value="0" selected disabled>{{ $t('selectDateModal.year_select', $store.state.locale) }}</option>
                                    <option
                                        v-for="(y, i) in years"  :key="i"
                                        :value="y"  
                                    >
                                        {{ y }}
                                    </option>
                                </select>
                                <span class="absolute right-4 top-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                                </span>
                            </div>
                            <span
                                v-if="$store.state.errors.errors.from" 
                                class="font-bold text-sm text-red-500" >
                                {{ $store.state.errors.errors.from[0] }}
                            </span>
                        </div>
                    </div>
                    <!-- Products -->
                    <div v-if="!limitError">
                        <!-- Type -->
                        <div  class="w-full flex items-center justify-between space-x-5">
                            <span class="text-xl">{{ $t('newInventoryModal.typology', $store.state.locale) }}:</span>
                            <label class="relative flex justify-between space-x-2 items-center group">
                                <span :class="reportType === 1 ? 'text-blue-500' : 'text-gray-400'">
                                    Usage
                                </span>
                                <div class="w-14 h-6 flex items-center flex-shrink-0 p-1 rounded-full duration-300 ease-in-out relative"
                                :class="reportType === 1 ? 'bg-blue-500' : 'bg-gray-300'">
                                    <button 
                                        class="absolute top-1 bottom-1 w-4 rounded-full bg-white duration-300"
                                        :class="reportType === 2 ? 'right-1' : 'left-1'"
                                        @click.prevent="toggleReportType"
                                    ></button>
                                </div>
                                <span :class="reportType === 2 ? 'text-blue-500': 'text-gray-400'">
                                    Delivery
                                </span>
                            </label>
                        </div>
                        <!-- Products -->
                        <div class="grid grid-cols-1 w-full h-56 lg:overflow-y-auto scrollbar-hidden">
                            <div class="flex items-start justify-start w-full overflow-x-auto scrollbar-hidden">
                                <table class="w-full"> 
                                    <thead class="border-b-2 dark:border-gray-700">
                                        <tr>
                                            <td class="p-2 text-sm">
                                                <button 
                                                    @click.prevent="toggleProducts"
                                                    class="w-5 h-5 rounded border text-white flex items-center justify-center"
                                                    :class="products.length && selected_products.length === products.length ? 'bg-light-green-500 border-light-green-500' : 'bg-gray-100 dark:bg-gray-700 dark:border-gray-500'">
                                                    <svg v-show="products.length && selected_products.length === products.length" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>    
                                                </button>
                                            </td>
                                            <td class="p-2 text-sm">
                                                {{ $t('productTable.cols.name', $store.state.locale) }}
                                            </td>
                                            <td class="p-2 text-sm">
                                                {{ $t('productTable.cols.formulator', $store.state.locale) }}
                                            </td>
                                            <td class="p-2 text-sm">
                                                {{ $t('productTable.cols.quantity', $store.state.locale) }} (Kg)
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody v-if="pageStatus === 'loading'">
                                        <tr>
                                            <td colspan="4" class="text-center  w-full p-3">
                                                <span v-if="setCompletionPercentage != 100">
                                                    Processing data... {{ setCompletionPercentage }}% completed
                                                </span> 
                                                <svg v-else class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="pageStatus === 'error'">
                                        <tr>
                                            <td colspan="4" class=" w-full">
                                                <div class="text-center flex flex-col items-center justify-center space-y-2 w-full p-3">
                                                    <h3 class="text-xl">
                                                        {{ $t('newInventoryModal.error_status', $store.state.locale) }}
                                                    </h3>
                                                    <button 
                                                        @click.prevent="init"
                                                        class="bg-white dark:bg-gray-800 rounded border dark:border-gray-700 px-4 py-2 hover:border-blue-500 dark:hover:border-blue-400 hover:text-blue-500 dark:hover:text-blue-400 duration-300 flex items-center justify-center space-x-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" xml:space="preserve" class="w-4 fill-current"><g><path d="M8.4,29c0.3,0,0.7-0.1,1-0.2l11.1-3.9c1.2-0.4,1.8-1.7,1.4-2.9c-0.4-1.2-1.7-1.8-2.9-1.4l-6.9,2.4 c3.3-8.6,11.7-14.4,21.3-14.4c10.5,0,19.6,7,22.2,17c0.3,1.2,1.5,1.9,2.7,1.6c1.2-0.3,1.9-1.5,1.6-2.7c-3.1-12-14-20.4-26.6-20.4 c-11.2,0-21.1,6.6-25.2,16.5l-2.1-6c-0.4-1.2-1.7-1.8-2.9-1.4c-1.2,0.4-1.8,1.7-1.4,2.9l3.8,10.9C6.1,28.3,7.2,29,8.4,29z"/><path d="M62.1,49.7L58,39c-0.3-0.7-0.8-1.3-1.5-1.6c-0.7-0.3-1.5-0.3-2.2,0l-11,4.2c-1.2,0.4-1.7,1.7-1.3,2.9 c0.4,1.2,1.7,1.7,2.9,1.3l6.9-2.6C47.8,50.5,40,55.3,31.3,55.3c-9.9,0-18.6-6.2-21.7-15.4c-0.4-1.2-1.7-1.8-2.8-1.4 c-1.2,0.4-1.8,1.7-1.4,2.8c3.7,11,14.1,18.4,25.9,18.4c10.3,0,19.6-5.7,24.3-14.5l2.3,6.1c0.3,0.9,1.2,1.4,2.1,1.4 c0.3,0,0.5,0,0.8-0.2C62,52.2,62.5,50.9,62.1,49.7z"/></g></svg>
                                                        <span class="text-sm">{{ $t('newInventoryModal.try_again', $store.state.locale) }}</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else-if="pageStatus === 'void' && !products.length">
                                        <tr>
                                            <td colspan="7" class="text-center  w-full p-3"> 
                                                <h3 class="text-xl">
                                                    {{ $t('newInventoryModal.void_status', $store.state.locale) }}
                                                </h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else-if="pageStatus === 'fetched' && !products.length">
                                        <tr>
                                            <td colspan="7" class="text-center  w-full p-3"> 
                                                <h3 class="text-2xl">
                                                    {{ $t('productTable.no_product', $store.state.locale) }}
                                                </h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else-if="products.length">
                                        <tr 
                                            v-for="product in products" :key="product.id"
                                            class="border-b dark:border-gray-700">
                                            <td class="p-2 text-sm">
                                                <button 
                                                    @click.prevent="toggleSingleProduct(product)"
                                                    class="w-5 h-5 rounded border text-white flex items-center justify-center"
                                                    :class="selected_products.length && selected_products.some(p => p.id === product.id) ? 'bg-light-green-500 border-light-green-500' : 'bg-gray-100 dark:bg-gray-700 dark:border-gray-500'">
                                                    <svg v-show="selected_products.length && selected_products.some(p => p.id === product.id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>    
                                                </button>
                                            </td>
                                            <td class="p-2 text-xs">
                                                {{ product.name }}
                                            </td>
                                            <td class="p-2 text-xs">
                                                <span>
                                                    {{ setFormulatorName(product) }}
                                                </span>
                                            </td>
                                            <td class="p-2 text-xs">
                                                {{ product.incheck_reference ? product.incheck_reference.quantity / 100 : '-' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <template v-if="pageStatus !== 'loading'" >
                            <div class="flex flex-col w-full space-y-2">
                                <div  v-if="noMatchStatus" class="flex flex-col space-y-1 w-full py-2 px-4 text-center">
                                    <p 
                                       
                                        class="text-sm text-red-500"
                                        v-html="$t('newInventoryModal.noMatchesDetected', $store.state.locale)">
                                    </p>
                                    <RouterLink
                                        :to="`/${$store.state.locale}/dashboard`" 
                                        class="text-sm text-red-500"
                                        v-html="$t('newInventoryModal.productsSection', $store.state.locale)">
                                    </RouterLink>
                                </div>
                                <!-- Attenzione: vi sono prodotti non associati. <br> 
                                    Torna alla sezione <RouterLink :to="`/${$store.state.locale}/dashboard`" >"Prodotti"</RouterLink> per completare il matching. -->
                                <div
                                    v-if="products.length && selected_products.length" 
                                    class="flex items-center justify-end space-x-3 py-2">
                                    <button 
                                        
                                        @click.prevent="openReportPreview"
                                        class="px-5 py-3 font-medium leading-5 text-center text-white rounded lg:mt-0 duration-300 lg:w-auto text-blue-500 hover:underline">
                                        Preview
                                    </button>
                                    <button 
                                        @click.prevent="newReport"
                                        class="px-5 py-3 font-medium leading-5 text-center text-white rounded lg:mt-0 duration-300 lg:w-auto"
                                        :class="setSubmitClasses"
                                        v-html="setSubmitContent"
                                        :disabled="newInventoryStatus !== 'void'">
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>
                    <!-- Limit Error -->
                    <div v-else class="h-56 flex flex-col space-y-2 items-center justify-center text-center">
                        <span class="text-xl font-semibold">
                            {{ $t('newInventoryModal.limitErrorTitle', $store.state.locale).replace('__reportRange', reportRange) }}
                        </span>
                        <span class="text-lg">
                            {{ $t('newInventoryModal.limitErrorBody', $store.state.locale) }}
                        </span>
                    </div>
                </template>
                <template v-else>
                    <div class="w-full h-full flex items-center justify-center p-10 text-center">
                        <span class="text-xl text-red-500">
                            {{ $t('newInventoryModal.maintainance', $store.state.locale) }}
                        </span>
                    </div>
                </template>
            </div>
        </div>
        <teleport to="body">
            <ReportPreview 
                v-if="products.length && showReportPreview"
                :referenceMonth="selected_month"
                :referenceYear="selected_year"
                :products="products.length"
                :selectedDate="selected_date"
                @closeModal="closeReportPreview"
            />
        </teleport>
    </div>
</template>

<script>
import { ref, 
         computed,
         defineAsyncComponent }     from 'vue';
import moment           from 'moment';
import { useStore }     from 'vuex';
import { useI18n }      from 'vue-i18n';
import { useRouter }      from 'vue-router';
import SnackbarComposables  from '../../composables/SnackbarComposables';
import useMatchAlgorithm  from '../../composables/useMatchAlgorithm';
import InventoryService from '../../services/InventoryService';
import ZdhcServiceV5 from '../../services/Zdhc/ZdhcServiceV5';
import ZdhcServiceV4 from '../../services/Zdhc/ZdhcServiceV4';
import ZdhcServiceV2 from '../../services/Zdhc/ZdhcServiceV2';
import ProductService from '../../services/ProductService';
import ProfileService from '../../services/ProfileService';
export default
{
    emits:['closeModal', 'newReport'],
    props:{ parentInventories: {required:true}},
    components:
    {
        ReportPreview: defineAsyncComponent( () => import('../report/ReportPreview.vue'))
    },
    setup(props, {emit})
    {
        /**
         *
         * Data
         *
         */
        const reportRange = 42;
        const maintainance = false;
        const router = useRouter();
        const store = useStore();
        const showReportPreview = ref(false);
        const noMatchStatus = ref(false);
        const pageStatus = ref('void');
        const reportType = ref(1);
        const totalProductsCount = ref(0);
        const newInventoryStatus = ref('void');
        const products = ref([]);
        const {t} = useI18n();
        const { addSnackbar } = SnackbarComposables();
        const { storeInventory } = InventoryService();
        const { indexProduct } = ProductService();
        const { updateSuscriptionExpiration } = ProfileService();
        const { inCheckReport: inCheckReportV5, inCheckSubscriptionStatus } = ZdhcServiceV5();
        const { incheckReport: inCheckReportV4 } = ZdhcServiceV4();
        const { getSubscriptionStatus } = ZdhcServiceV2();
        const { checkForExistingZdhcProductV5, counter } = useMatchAlgorithm();
        const selected_products = ref([]);
        let selected_month= ref(moment().format('M'));
        let selected_year= ref(moment().format('YYYY'));
        let dateLimit= ref(moment().subtract(reportRange, 'days').format('YYYY-MM-DD'));
        let limitError= ref(false);
        let months = ref([
            'jan',
            'feb',
            'mar',
            'apr',
            'may',
            'jun',
            'jul',
            'aug',
            'sep',
            'oct',
            'nov',
            'dec',
        ]);
        let years = ref(Array.from({length: moment().format('YYYY') - 1900}, (value, index) => 1901 + index));
        const selected_date = ref(moment().format('YYYY-MM-DD'));

        /**
         *
         *  Computed
         *
         */
        const setSubmitContent = computed( () => 
        {
            switch(newInventoryStatus.value)
            {
                case 'void':
                    return t('newInventoryModal.create_report', store.state.locale);
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                default:
                    return t('newInventoryModal.create_report', store.state.locale);
            }
        });
        const setSubmitClasses = computed( () =>
        {
            switch(newInventoryStatus.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-300';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });
        const setCompletionPercentage = computed( () => 
        {
            if(totalProductsCount.value === 0) return 0;
            else 
            {
                let p = (counter.value * 100) / totalProductsCount.value;
                return p.toFixed(0);
            }
        });
        const isDateInRange = computed( () =>
        {
            return  moment(selected_date.value).isBetween(dateLimit.value, moment(), undefined, '[]');
        });

        /**
         *
         * Methods
         *
         */
        const init = async () => 
        {
            if(!isDateInRange.value) 
            {
                limitError.value = true;
            }
            else 
            {
                noMatchStatus.value = false;
                limitError.value = false;
                pageStatus.value = 'loading';
                products.value = [];
                checkProducts();
            }
        };
        const getProducts = async () => 
        {
            const res = await indexProduct( 
                    moment(selected_date.value).startOf('month').format('YYYY-MM-DD'),
                    'desc', 
                    null, 
                    null,
                    100,
                    15,
                    false
            );
            if(res)
            {
                if('results' in res)
                {
                    products.value = res.results;
                    products.value.forEach( p => p.incheck_reference = p.incheck_references[0]);
                    selected_products.value = products.value.filter(e => e.incheck_reference.suggestion_id || e.incheck_reference.zdhc_product_id || e.incheck_reference.no_match_id );
                    if(products.value.some(product => !product.incheck_reference.suggestion_id && !product.incheck_reference.zdhc_product_id && !product.incheck_reference.no_match_id)) noMatchStatus.value = true
                    pageStatus.value = 'fetched';
                }
            }
            else pageStatus.value = 'error';

        }
        const checkProducts = async () => 
        {
            const indexRes = await indexProduct( 
                    moment(selected_date.value).startOf('month').format('YYYY-MM-DD'),
                    'desc', 
                    null, 
                    null,
                    100,
                    15,
                    false
            );
            if(indexRes?.results)
            {
                totalProductsCount.value = indexRes.results.length;
                const res = await checkForExistingZdhcProductV5(indexRes.results);
                if(res?.status && res?.status === 'success') getProducts();
                else pageStatus.value = 'error';
            }   
            else pageStatus.value = 'error';
        }
        const setSelectedDate = () => 
        {
            selected_date.value = moment(`${selected_year.value}-${selected_month.value}-1`, 'YYYY-MM-D').format('YYYY-MM-DD');
            if(!moment(selected_date.value).isSame(moment(), 'month'))
            {
                selected_date.value = moment(selected_date.value).endOf('month').format('YYYY-MM-DD');
            }
            init();
        };
        const toggleSingleProduct = (product) => 
        {
            let id = product.id;
            if(selected_products.value.some(p => p.id === id))
            {
                return selected_products.value = selected_products.value.filter(p => p.id != id);
            }
            else 
            {   
                if(product.incheck_reference.suggestion_id || product.incheck_reference.zdhc_product_id || product.incheck_reference.no_match_id) return selected_products.value.push(product);
                else return addSnackbar('warning','This product doesn\'t have a match or suggestion');
            }
        };
        const toggleProducts = () => 
        {
            if( products.value.some( e => !e.incheck_reference.suggestion_id && !e.incheck_reference.zdhc_product_id && !e.incheck_reference.no_match_id)) return addSnackbar('warning', 'Impossible to select all products, some of them have no match or suggestion')
            return selected_products.value.length === products.value.length 
                    ? selected_products.value = [] 
                    : selected_products.value = products.value;
        };
        const newReport = async () => 
        {
            if( products.value.some( e => !e.incheck_reference.suggestion_id && !e.incheck_reference.zdhc_product_id && !e.incheck_reference.no_match_id)) return addSnackbar('warning', 'Impossible to generate a report, make sure each producta has been associated');
            //let previous_month_date = moment().subtract(1, 'month');
            if(!selected_products.value.length) return addSnackbar('warning','Select at least one product');
            else if(props.parentInventories.some( i => moment(i.reference_date).isSame(moment(selected_date.value), 'month') && moment(i.reference_date).isSame(moment(selected_date.value), 'year'))) return addSnackbar('warning','An inventory for this period already exists');
            else if(!isDateInRange.value) return addSnackbar('warning','You cannot generate reports for this date');
            newInventoryStatus.value = 'loading';
            let incheckProducts = [];
            selected_products.value.forEach( p => 
            {
                let reference = p.incheck_reference;
                let incheckProduct = null;
                if(reference.match && reference.match.guid)
                {
                    incheckProduct = 
                    {
                        productGUID : reference.match.guid || null,
                        weight      : reference.quantity / 100 || 0,
                        unit        : "Kg"
                    };
                }
                else if(reference.suggestion_id && reference.suggestion?.zdhc_product?.guid)
                {
                    incheckProduct = 
                    {
                        productGUID : reference.suggestion?.zdhc_product?.guid || null,
                        weight      : reference.quantity / 100 || 0,
                        unit        : "Kg"
                    };
                }
                else if(reference.no_match_id)
                {
                    incheckProduct = 
                    {
                        productGUID : reference.no_match_id || null,
                        weight      : reference.quantity / 100 || 0,
                        unit        : "Kg"
                    };
                }


                if(incheckProduct) incheckProducts.push(incheckProduct);
            });
            if(incheckProducts.length)
            {
                let incheckOptions = 
                {
                    'OrganizationGUID': store.state.auth.orgGUID,
                    'month': moment(selected_date.value).format('MM'),
                    'year': moment(selected_date.value).format('YYYY'),
                    'type': reportType.value, //  1: Usage || 2: Delivery
                    inventory:[...incheckProducts]
                }
                let res;
                if(process.env.VUE_APP_ZDHC_VERSION == 2) res = await inCheckReportV4(incheckOptions);
                else res = await inCheckReportV5(incheckOptions);
                if(res.incheckReport)
                {
                    if(!store.state.auth.user.subscription_expires_at) checkUserIncheckSubscriptionStatus();
                    storeInventory({reference_date: selected_date.value, products: selected_products.value, incheck_report: res.incheckReport})
                    .then(res => 
                    {
                        if(res === 200) return emit('newReport');
                        newInventoryStatus.value = 'success';
                    })
                    .catch( () => 
                    {
                        newInventoryStatus.value = 'error';
                    });
                }
            }
        };
        const toggleReportType = () => 
        {
            return reportType.value === 1 ? reportType.value = 2 : reportType.value = 1;
        };
        const openReportPreview = () => 
        {
            showReportPreview.value = true;
            document.body.classList.add('overflow-hidden');
        };
        const closeReportPreview = () => 
        {
            showReportPreview.value = false;
            document.body.classList.remove('overflow-hidden');
        };
        const checkUserIncheckSubscriptionStatus = async () => 
        {
            let res;
            if(process.env.VUE_APP_ZDHC_VERSION == 2) res = await getSubscriptionStatus(store.state.auth.orgGUID);
            else res = await inCheckSubscriptionStatus(store.state.auth.orgGUID);

            if(res)
            {
                if(res.status === 'ok')
                {
                    if(res.expirationDate) updateSuscriptionExpiration({subscription_expires_at: res.expirationDate});
                }
                else console.log('non valida');
            }
        };
        const setFormulatorName = (product) => 
        {
            if(product.formulator)
            {
                return product.formulator.name.length > 20
                                ? product.formulator.name.substring(0, 17)+'...'
                                : product.formulator.name;
            }
            return product.formulator_name ?? '-';
        }

        /**
         *
         * Hooks
         *
         */
        init();

        return {
            /* Data */
            reportRange,
            maintainance,
            showReportPreview,
            selected_date,
            pageStatus,
            newInventoryStatus,
            selected_month,
            selected_year,
            selected_products,
            months,
            years,
            products,
            reportType,
            limitError,
            noMatchStatus,
            router,
            /* Computed */
            setSubmitContent,
            setSubmitClasses,
            setCompletionPercentage,
            /* Methods */
            init,
            setSelectedDate,
            toggleSingleProduct,
            toggleProducts,
            newReport,
            toggleReportType,
            openReportPreview,
            closeReportPreview,
            setFormulatorName
        }
    }
}
</script>